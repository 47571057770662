import React, { useEffect, useRef } from 'react';
import './css/bootstrap.min.css'; // Import Bootstrap CSS
import { Carousel } from 'bootstrap'; // Import Bootstrap Carousel component

function Slider() {
  const carouselRef = useRef(null);

  useEffect(() => {
    const carouselElement = carouselRef.current;
    const myCarousel = new Carousel(carouselElement, {
      interval: 3000,
      pause: 'false',
    });

    return () => {
      myCarousel.dispose(); // Cleanup when component unmounts
    };
  }, []);

  return (
    <div>
      <div ref={carouselRef} id="myCarousel" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner" id="section5">
          <div className="carousel-item active">
            <img src="images/Slide1.png" className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
            <img src="images/Slide2.png" className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
            <img src="images/Slide3.png" className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
            <img src="images/Slide4.png" className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
            <img src="images/Slide5.png" className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
            <img src="images/Slide6.png" className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
            <img src="images/Slide7.png" className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
            <img src="images/Slide8.png" className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
            <img src="images/Slide9.png" className="d-block w-100" alt="Slide 1" />
          </div>
          <div className="carousel-item">
            <img src="images/Slide10.png" className="d-block w-100" alt="Slide 1" />
          </div>
          {/* Add other carousel items similarly */}
        </div>
      </div>
    </div>
  );
}

export default Slider;
