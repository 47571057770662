import React from 'react'

function Leader() {
  const heroStyle = {
    backgroundColor:'#17144B',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    color: 'rgb(235, 240, 240)',
    textShadow: '2px 2px 4px rgba(15, 15, 15, 0.9)',
  };  
  
  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
  };

  const textStyle = {
    color: 'rgb(235, 240, 240)',
    textShadow: '2px 2px 4px rgba(15, 15, 15, 0.9)',
    fontFamily: 'Arial, sans-serif',
  };

      const boxSize ={
        height: 240,
        width:440,
        padding:5,
      }
    
      return (
        <div className="team " style={heroStyle} id="section3">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="services__title" style={{marginTop:50,padding:5}}>
                  <div className="section-title">
                    
                    <h2>Our Leadership</h2>
                  </div>
                  <h4 style={textStyle}>We are proud to have eminent industry experts guiding the group. We enable seamless collaboration between industry and academia for boundaryless innovation. Started by 2 women entrepreneurs, as a lean partnership organization, we focussed on integrating industry and academia to innovate in the real world. Currently we have a strong team onboard with our vision to “ Give every student a dream” and build a future with an unprecedented imagination and innovation</h4>
                  {/* ... Other paragraphs ... */}
                  
                </div>
              </div>
              </div>
              <div className="row">
              <div className="col-lg-12">
                <div className="row">
                                <div className="col-lg-3 col-md-3 col-sm-6">
                              
                                <div className="services__item" style={{marginTop:50,padding:5}}>
                                    
                                    <div className="" >
                                            <img src="images/Riti 1.jpg"  style={{borderRadius:40,}}  alt="" />
                                        </div>
                                      
                                        <h4 style={heroStyle}>RITI CHATTERJEE</h4>
                                        <b style={textStyle}>Managing Director, Plumule Research</b>
                                        <br /><br />
                                        <p style={heroStyle}>
                                        Alum: BE Electrical Gold Medalist (NIT DGP), IIM Kolkata APFFB </p><p style={textStyle}>
                30+ years of program delivery in information technology @ Cognizant, TCS, NTPC, Reliance, IBM, MN DasturCo 
                Industry 4.0 Leader in IoT, AI, Contributor to ISO JTC for Cloud Implementation and Global RFID standards

                                        </p>
                    
                                        
                                        </div>
                    
                                    </div>
    
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                  
                                  <div className="services__item" style={{marginTop:50,padding:5}}>
                                      
                                      <div className="" >
                                          <img src="images/MOITREYEE.jpg" style={{borderRadius:40,}}  alt="" />
                                      </div>
                                    
                                      <h4 style={heroStyle}>MOITREYEE BHATTACHARYA

                </h4>
                                      <b style={textStyle}>Chief Operating Officer, Plumule Research

                </b>
                                      <br /><br />
                                      <p style={heroStyle}>Alum:  LLB, University of Kolkata<br />

                                      Paced the growth of Plumule Research and its operations under strong governance of the company law.


                </p>
                  
                                    </div>  
                                      </div>


          <div className="col-lg-3 col-md-3 col-sm-6">
              
              <div className="services__item" style={{marginTop:50,padding:5}}>
                  
                  <div className="" >
                          <img src="images/RIT.png"  style={{borderRadius:40,}}  alt="" />
                      </div>
                    
                      <h4 style={heroStyle}>RIT CHATTERJEE</h4>
                      <b style={textStyle}>CFO, Plumule Research</b>
                      <br /><br />
                      <p style={heroStyle}>
                      Alum: MSC. Finance BI Norwegian Business School, Oslo, BSC. Economics Lancaster University UK
</p><p style={heroStyle}>
 Expert in Financial Planning & Analysis, Investment Management, Commodity Trading,  Structured Project Financing, Company Valuations, Counterparty Negotiations
CFA Research Challenge EMEA Champion   </p>
  
                      
                      </div>
                      </div>

                      <div className="col-lg-3 col-md-3 col-sm-6">
              
              <div className="services__item" style={{marginTop:50,padding:5}}>
                  
                  <div className="" >
                          <img src="images/AMARTYA.png"  style={{borderRadius:40,}}  alt="" />
                      </div>
                    
                      <h4 style={heroStyle}>AMARTYA BHATTACHARYA</h4>
                      <b style={textStyle}>AI Innovation Strategist, Plumule Research</b>
                      <br /><br />
                      <p style={heroStyle}>
                      Alum: BTech, Computer Engineering, University of Kolkata
                      Junior Research Fellow@ IISc| Former Computer Vision Research @Osaka University| Former Research Assistant -Arizona State University| Former Intern- ISRO
Expert in AI & Deep Learning, author of international research papers like Diabetic Retinopathy Detection Multi-modal fusion model for predicting adverse outcomes, Student Gaze Analytics

                      </p>
  
                      
                      </div>
                    </div>
  
                  </div>
                </div>   
    
    
    
                <div className="row">

                <div className="col-lg-3 col-md-3 col-sm-6">
              
              <div className="services__item" style={{marginTop:50,padding:5}}>
                  
                  <div className="" >
                          <img src="images/Dr LEELAVATI.jpg"  style={{borderRadius:40,}}  alt="" />
                      </div>
                    
                      <h4 style={heroStyle}>DR. LEELAVATI THAKUR</h4>
                      
                      
                      <p style={heroStyle}>
                      <b style={textStyle}>Advisor, EHR, EMR, Medical Devices, Life Support
 (NIT DGP)</b><br /><br />
 </p><p style={heroStyle}>
 Alum: North Bengal Medical College
30+ years of experience in critical in major hospitals across India, Fellowship in TSS, eminent practice and consulting in Advanced Cardiac Life Support, Medical Device and ICU setup, an active member of the Indian Society of Critical Care Medicine
  </p>
  
                      
                      </div>
                    </div>


                                <div className="col-lg-3 col-md-3 col-sm-6">
                              
                                <div className="services__item" style={{marginTop:50,padding:5}}>
                                    
                                    <div className="" >
                                            <img src="images/SOUMITRA.png"  style={{borderRadius:40,}}  alt="" />
                                        </div>
                                      
                                        <h4 style={heroStyle}>SOUMITRA PODDAR</h4>
                                        <b style={textStyle}>Advisor, Digital Transformation Strategy
</b>
                                        <br /><br />
                                        <p style={heroStyle}> 
                                        Alum: VS Gupta School of Mgmt., IIT KGP
Visiting Faculty (VGSOM , IIT Kharagpur , IISWBM  )
Three Decades of experience in the field of Big 5 Management Consulting, Global Transformation programs
                                        </p>
                    
                                        
                                        </div>
                    
                                    </div>
    
                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                  
                                  <div className="services__item" style={{marginTop:50,padding:5}}>
                                      
                                      <div className="" >
                                          <img src="images/ARGHYA2.png" style={{borderRadius:40,}}  alt="" />
                                      </div>
                                    
                                      <h4 style={heroStyle}>DR ARGHYA MAJUMDER
                </h4>
                                      <b style={textStyle}>Advisor, Iron & Steel Industry
                </b>
                                      <br /><br />
                                      <p style={heroStyle}>Alum: BE , ME & PhD in METALLURGY, Jadavpur University, Holds a Post-Graduate Diploma in Computer Application (PGDCA) from Pondicherry University Director of the School of Mines and Metallurgy at Kazi Nazrul University  Asansol. <b />
                                      Ex.Deputy General Manager (Quality Assurance and Technology Development) at Visakhapatnam Steel Plant in Visakhapatnam. Certified as a lead and internal auditor for Quality Management Systems (QMS) and Integrated Management Systems (IMS) by BVC, UK.

                </p>
                  
                                    </div>  
                                      </div>


          <div className="col-lg-3 col-md-3 col-sm-6">
              
              <div className="services__item" style={{marginTop:50,padding:5}}>
                  
                  <div className="" >
                          <img src="images/ROBIN KUMAR BISWAS 1.jpg"  style={{borderRadius:40,}}  alt="" />
                      </div>
                    
                      <h4 style={heroStyle}>ROBIN KUMAR BISWAS
</h4>
                      <b style={textStyle}>Advisor, Machine Condition Monitoring, Vibration Analysis
</b>
                      <br /><br />
                      <p style={heroStyle}>

                      Alum: B.E. METALLURGY, NIT DURGAPUR , MTech , PhD in  Mechanical Engineering.<br />Designation: Ex Chief Scientist of CMERI-CSIR<br />

35+ years of experience in power plants, process industries, mining industry and steel industries
DVC group, PTPS, WBSEB, BSEB, PCBL, Phosphate Company of India Ltd., Indian Charge Chrome Ltd., OSIL,ECIL, BCCL, CMPDIL, DSP, BSP, VISL

                      </p>
  
                      
                      </div>
                      </div>

                    
  
                  </div>
                </div>   
    
    
    
               



              </div>
            </div>
          
        
      
  )
}

export default Leader
