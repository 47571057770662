import React from 'react';
import Menu from './Menu';
import Hero from './Hero';
import Service from './Service';
import Leader from './Leader';
import Slider from './Slider';
import Team from './Team';
import Contact from './Contact';

function App() {
  return (
    <div id="section1">
     <Menu /> 
     <Hero />
     <Service />
     <Leader /> 
     <Slider />
     {/* <Team /> */}
     <Contact />
    </div>
  );
}

export default App;
