import React from 'react';
import logo from './images/logo.png';


function Menu() {

    const textStyle = {
        color: 'white',
        marginBottom: '2vw',
      
      };
      const linkStyle = {
        color: 'white',
        textDecoration: 'none', 
      };

      
    return (
        <div className="App fixed-top">
           

            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="header__logo">
                                <a href="./index.html">
                                <div class="container">
                                    <div class="row">
                                        <div class="col4">
                                        <img src={logo} alt="Logo" height="50vw" width="50vw"  style={{ color: 'aliceblue' }} />
                                        </div>
                                        <div class="col8 m-2">
                                        <a style={linkStyle}>PLUMULE RESEARCH LLP</a>
                                        </div>
                                    </div>
                                
                                   
                                    
                                    </div>
                                </a>
                            </div>
                            
                        </div>
                        <div className="col-lg-9">
                            <div className="header__nav__option">
                                <nav className="header__nav__menu mobile-menu">
                                    <ul>
                                        <li className="active" style={textStyle} ><a href="#section1">Home</a></li>
                                        <li><a href="#section2" style={textStyle}>About us</a></li>
                                        <li><a href="#section3" style={textStyle}>Our Leadership</a></li>
                                        <li><a href="#section5" style={textStyle}>Product & framework</a></li>
                                        <li><a href="#section6" style={textStyle}>Our Team</a></li>
                                        <li><a href="#section4" style={textStyle}>Contact</a></li>
                                    </ul>
                                </nav>
                                
                            </div>
                        </div>
                    </div>
                    <div id="mobile-menu-wrap" style={textStyle}></div>
                </div>
            </header>
        </div>
    );
}

export default Menu;
