import React from 'react';
import './css/bootstrap.min.css';


function Hero() {
  const heroStyle = {
    backgroundImage: `url('images/emerging-ai-img12.jpg')`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '100vh', // Set the height to cover the entire viewport
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'rgb(235, 240, 240)',
    textShadow: '2px 2px 4px rgba(15, 15, 15, 0.9)',
  };

  const textStyle = {
    color: 'white',
    marginBottom: '2vw',
    marginTop: '2vw',
  };
  const linkStyle = {
    color: 'white',
    textDecoration: 'none', 
  };

  return (
    <div className="hero" style={heroStyle}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div style={textStyle}>
              <span style={textStyle}>For Industry 4.0 Technology</span>
              <h2 className="text-white" style={textStyle}>Unlock your Business Potential with Industry 4.0 Technology that you can trust</h2>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Hero;
