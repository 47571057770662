import React from 'react';

function Service() {
  const linkStyle = {
    color: 'white',
    textDecoration: 'none',
  };

  const textStyle = {
    color: 'rgb(235, 240, 240)',
    textShadow: '2px 2px 4px rgba(15, 15, 15, 0.9)',
    fontFamily: 'Arial, sans-serif',
  };
const boxSize ={
  height: 300,
  width:400,
  padding:5,
}

  return (
    <div className="services spad" id="section2">
      <div className="container">
        <div className="row">
          <div className="col-lg-4">
            <div className="services__title">
              <div className="section-title">
                
                <h2 style={textStyle}>What We do?</h2>
              </div>
              <p style={textStyle}>We work hard to make your journey simpler and safer every day</p>
              <p style={textStyle}>With a focus on high quality, performing systems, green AI and field-adapted solutions</p>
              <p style={textStyle}>Our dynamic simulations and predictive analytics ensure economy and effectiveness</p>
              <p style={textStyle}>We bring you a global network in Industry and Academic research</p>
              <p style={textStyle}>And hence a rewarding journey in digitalization that is resilient & sustainable</p>
              {/* ... Other paragraphs ... */}
              
            </div>
          </div>
          <div className="col-lg-8">
            <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              
                <div className="services__item">
                    
                    <div className="" style={boxSize}>
                        <img src="images/Energy.jpg" className="rounded float-start" alt="" />
                    </div>
                  
                    <h4 style={textStyle}>Energy Systems</h4>

                    <p style={textStyle}>
                        <b>Power Plant KPI Dashboards:</b> Detailed system-level performance metrics, alerts, and overall station performance.</p>
                    <p style={textStyle}>
                        <b >Predictive Maintenance:</b> Equipment performance trends, Deep Neural Network based root cause analysis of Boiler Tube leakage.</p>                             
                    <p style={textStyle}>
                        <b >Intelligent Process Automation:</b> Smart metering and grid balancing solutions, power transformer insulating oil dielectric strength </p>
                    
                    </div>

                </div>

            <div className="col-lg-6 col-md-6 col-sm-6">
                
                <div className="services__item">
                  <div className="" style={boxSize}>
                    <img src="images/mine.jpg" className="rounded float-start" alt="" />
                  </div>
                  <h4 style={textStyle}>Mines and Metals</h4>
                  <p style={textStyle}>
                  <b>Quality Control: </b>Anomaly detection and defect prediction within both continuous and discrete process manufacturing.
                  </p><p style={textStyle}><b>Industries: </b>Iron Ore processing, Steel manufacturing, Coal Mining.<br />
                  Fleet management, fuel management, GPS Tracking, Equipment predictive maintenance, remote mode control of operations. 
                  </p>
                  {/* ... Other paragraphs ... */}
                </div>

            </div>
            </div>   






            <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
              
                    <div className="services__item">
                    <div className="" style={boxSize}>
                        <img src="images/health.jpg" className="rounded float-start" alt="" />
                    </div>
                    <h4 style={textStyle}>Healthcare</h4>
                        <p style={textStyle}><b >Patient data protection:</b> We provide integrated solutions to secure patient personal identification data as per the Digital Personal Data Protection Act, 2023.</p> 
                        <p style={textStyle}><b >    Health monitoring solutions</b> at home for geriatric and patients suffering from chronic disabilities. </p>
                        <p style={textStyle}><b >   EMR-EHR integration frameworks</b> aligned to National Digital Health Mission.</p> 
                                    
                    
                    </div>
                    </div>
           
            <div className="col-lg-6 col-md-6 col-sm-6">

                <div className="services__item">
                  <div className="" style={boxSize}>
                    <img src="images/education.jpg" className="rounded float-start" alt="" />
                  </div>
                  <h4 style={textStyle}>Education</h4>
                  <p style={textStyle}>
                  We run post graduate programs and short term experiential learning courses in Embedded Systems 
                  and Artificial Intelligence.</p>
                  <p style={textStyle}>Centered on real world projects and problem solving,  the participants work in state of art  laboratories  in Industry 4.0 technologies</p><br /> 
                  <p style={textStyle}> <b>Technology: </b>Industrial IoT, AI , CyberSecurity, PCB design , Smart Metering, PLC Programming, Additive Manufacturing, Robotics</p>
                 
                </div>

            



            </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
   
  );
}

export default Service;



