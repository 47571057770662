import React from 'react';

function Contact() {

    const textStyle = {
        color: 'rgb(235, 240, 240)',
        textShadow: '2px 2px 4px rgba(15, 15, 15, 0.9)',
        fontSize:16,
        paddingTop:150,
        fontFamily: 'Arial, sans-serif',
      };

      const textStyle1 = {
        color: 'rgb(235, 240, 240)',
        textShadow: '2px 2px 4px rgba(15, 15, 15, 0.9)',
        fontSize:17,
        paddingTop:80,
      };
      const heroStyle = {        
        color: 'rgb(235, 240, 240)',
        textShadow: '2px 2px 4px rgba(15, 15, 15, 0.9)',
      };  
      

  return (
    
    <div className="team " style={heroStyle} id="section4">
      <div className="container">
            <div className="row">
            <div className="col-lg-5" style={textStyle1}>
             
<iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.8829780713363!2d87.2852126741059!3d23.53671097881675!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f7720e22b2bf49%3A0xd5938a6de8d697b0!2sWebel%20IT%20PARK%20-%20Durgapur!5e0!3m2!1sen!2sin!4v1700819822870!5m2!1sen!2sin"
              width="400"
              height="380"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>

</div>

<div className="col-lg-3" style={textStyle}>
         <b style={textStyle}>Webel IT PARK - Durgapur <br />
            Palashdiha Industrial Area, Durgapur,<br /> West Bengal 713208</b>
          
         </div>
            

         <div className="col-lg-4">    
          <div name="contact"
      className=" h-screen   text-white  "
    >
      <div className="flex flex-col  justify-center  mx-auto  h-full">
        <div className="">
        
          <p className="text-4xl font-bold inline  border-gray-500 p-10" >
            Contact
          </p>
          <p className=" pl-5" style={textStyle}>Submit the form below to get in touch with us</p>
        </div>

        <div className=" flex justify-center w-full ">
          <form
            action="https://getform.io/f/99338d7e-1362-47f6-9b4f-f1f7d76471af"
            method="POST"
            className=" flex flex-col w-3/4"

          >
            <input
              type="text"
              name="name"
              width="650px"
              placeholder="Enter your name"
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none w-full" // Adjusted width using w-full
            />
            <input
              type="text"
              name="email"
              placeholder="Enter your email"
              className="my-4 p-2 bg-transparent border-2 rounded-md text-white focus:outline-none w-full" // Adjusted width using w-full
            />
            <textarea
              name="message"
              placeholder="Enter your message"
              rows="10"
              className="p-2 bg-transparent border-2 rounded-md text-white focus:outline-none w-full" // Adjusted width using w-full
            ></textarea>

            <button className="text-white bg-gradient-to-b from-cyan-500 to-blue-500 px-6 py-3 my-8 mx-auto flex items-center rounded-md hover:scale-110 duration-300">
              Send
            </button>
          </form>
        </div>
      </div>
    </div>

    </div>
    </div>
</div>
</div>
    
  );
}

export default Contact;
